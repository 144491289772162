import React, { useState } from "react"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import { RichText } from "prismic-reactjs"
import LocalizedLink from "../components/localizedLink"
import linkResolver from "../utils/linkResolver"
import { isExternalLink, isDocumentLink } from "../utils/utils"

import axios from "axios"
import { useForm } from "react-hook-form"
import { regEmail } from "../utils/utils"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const Contact = ({ contactData }) => {
  const [formSent, setFormSent] = useState("")
  const { node } = contactData.edges[0]
  const { register, handleSubmit, errors } = useForm()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async formData => {
    setFormSent({ sending: true })
    const captchaToken = await executeRecaptcha("homepage")
    const emails = ['contact@monstock.net']
    axios
      .post("/.netlify/functions/contactForm", { formData, captchaToken, emails })
      .then(function(response) {
        setFormSent(response)
      })
      .catch(function(error) {
        setFormSent({ error: error.message })
      })
  }

  return (
    <div className="container mt-2" css={contactCss}>
      <div className="columns is-multiline">
        <div className="column is-5-desktop is-12-touch">
          <h3 className="title is-3 has-text-weight-semibold">
            {RichText.asText(node.title)}
          </h3>
          <p className="is-size-5 has-text-weight-light">
            {node.subtitle && RichText.asText(node.subtitle)}
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form__outline mt-6"
          >
            {node.form.map(field => {
              return (
                <div key={field.field_id} className="field">
                  <div className="control">
                    {field.field_placeholder && (
                      <label
                        className="label is-hidden"
                        htmlFor={field.field_id}
                      >
                        {field.field_placeholder}
                      </label>
                    )}
                    {field.field_type === "textarea" ? (
                      <textarea
                        name={field.field_id}
                        id={field.field_id}
                        rows="1"
                        className="textarea"
                        placeholder={field.field_placeholder}
                        ref={register({
                          required: field.field_is_required ? true : false,
                        })}
                      ></textarea>
                    ) : (
                      <input
                        className="input"
                        type={field.field_type}
                        name={field.field_id}
                        placeholder={field.field_placeholder}
                        id={field.field_id}
                        ref={
                          field.field_type === "email"
                            ? register({
                                pattern: regEmail,
                                required: field.field_is_required
                                  ? true
                                  : false,
                              })
                            : register({
                                required: field.field_is_required
                                  ? true
                                  : false,
                              })
                        }
                      />
                    )}
                    {errors[field.field_id] && (
                      <p className="help is-danger">This field is required</p>
                    )}
                  </div>
                </div>
              )
            })}
            <div className="field">
              <div className="control checkbox_custom py-5">
                <input
                  type="checkbox"
                  name="terms"
                  ref={register({ required: true })}
                  id="checkbox_custom"
                />
                <label htmlFor="checkbox_custom">
                  {RichText.render(node.terms_richtext, linkResolver)}
                </label>

                {errors.terms && (
                  <p className="help is-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="field">
              <div className="control">
                {formSent === "" && (
                  <button className="button is-rounded button__primary has-text-white is-medium px-6">
                    {node.button_send}
                  </button>
                )}
                {formSent && formSent.sending && (
                  <button className="button is-rounded" disabled>
                    ...
                  </button>
                )}
                {formSent.status && formSent.status === 200 && (
                  <button className="button" title="Disabled button" disabled>
                    {node.form_success_message}
                  </button>
                )}
                {formSent.status && formSent.status !== 200 && (
                  <div className="notification is-rounded is-danger">
                    {formSent.error}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="column is-7 is-hidden-touch">
          <Image sharp={node.contact_imageSharp} image={node.contact_image} />
        </div>
      </div>
      <div className="columns">
        {node.about_us.map(action => {
          return (
            <div key={action.about_us_id} className="column is-4">
              {isExternalLink(action.about_us_link) && (
                <a
                  href={action.about_us_link.url}
                  target={action.about_us_link.target}
                  rel="noopener noreferrer nofollow"
                >
                  <div className="notification is-size-5">
                    <span
                      className={
                        "has-text-primary is-size-4 mb-3 icon icon-" +
                        action.about_us_id.toLowerCase()
                      }
                    />
                    {RichText.render(action.about_us_content, linkResolver)}
                  </div>
                </a>
              )}
              {isDocumentLink(action.about_us_link) && (
                <LocalizedLink to={action.about_us_link._meta.uid}>
                  <div className="notification is-size-5">
                    <span
                      className={
                        "has-text-primary is-size-4 mb-3 icon icon-" +
                        action.about_us_id.toLowerCase()
                      }
                    />
                    {RichText.render(action.about_us_content, linkResolver)}
                  </div>
                </LocalizedLink>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Contact

const contactCss = css`
  .notification {
    background-color: ${colors.zircon};
    height: 100%;
  }

  .notification a:not(.button):not(.dropdown-item) {
    text-decoration: none;
  }

  .checkbox_custom {
    display: flex;

    label {
      padding-right: 20px;
      margin-top: 4px;
      margin-left: -10px;
      position: relative;
      p {
        padding-left: 20px;
        margin-top: -2px;
      }
      &::before {
        content: "";
        display: inline-block;
        height: 11px;
        width: 11px;
        border: 0px solid;
        border-radius: 50%;
        background-color: ${colors.blueHr};
        position: absolute;
        top: 3px;
      }

      &::after {
        content: "";
        display: inline-block;
        height: 6px;
        width: 9px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg);
        position: absolute;
        left: 1px;
        top: 4px;
      }
    }

    input[type="checkbox"] {
      opacity: 0;

      + label::after {
        content: none;
      }

      &:checked + label::after {
        content: "";
      }
    }
  }

  @media (min-width: 1024px) {
    .gatsby-image-wrapper {
      width: 150%;
    }
  }
`
